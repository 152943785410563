.container {
  padding: 40px;
  /* background: #419be0; */
}

.testimonial-shadow {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07) !important;
}

.slick-slide img {
  margin: auto;
}

.slick-list {
  /* overflow: visible !important; */
}

 .slick-slide .test{
  display: flex !important;
  max-width: 80% !important; 
  /* overflow: hidden !important; */
}

.slick-dots {
  bottom: -40px !important;
}

.slick-dots li button:before  {
  /* width: 40px !important; */
color: #F2F2F2 !important;
opacity: 1 !important;
font-size: 16px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color:  #00B061 !important;
}